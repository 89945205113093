import * as React from "react"

import { Helmet } from "react-helmet";

import PageLayout from "../components/PageLayout";

import HeroBanner from "../components/HeroBanner";
import Module from "../components/Module";

import { Button } from "react-bootstrap"

import HeroImage from "../images/iStock-1322139328.jpg";
import AbstractWavesImage from "../images/iStock-1310435034.jpg";

import ImageTCX1_1 from "../images/TC_X1_1.png";
import CoilImage_1 from "../images/coil_1.png"
import PetriImage from "../images/iStock-1138648016.jpg"
import CalciumCarbonateImage from "../images/iStock-1291313034.jpg"

const ConductivityMeterPage = () => {

  return (
    <PageLayout>
      <Helmet htmlAttributes={{
        lang: 'en',
      }}>
        <title>Tayos Corp - TC&#8209;X1 Electromagnetic Conductivity Sensor</title>
      </Helmet>
      <HeroBanner backgroundImage={HeroImage} />

      <Module image={CoilImage_1} imageAlt="Close-up image of the coil sensing surface of the TC&#8209;X1 electromagnetic conducitivy meter, detatched from the enclosure, showing the S-winding of the coil." right>
        <h1 className="display-4">Introducing the TC&#8209;X1</h1>
        <p className="lead text-muted">
          By default, sensitive in the range 0.5&nbsp;&#8209;&nbsp;350&nbsp;mS/cm<br />Precise to +/-&nbsp;0.5&nbsp;mS/cm
        </p>
        <p className="lead">On sale now for <b><u>$595</u></b></p>
        <p>
        The TC&#8209;X1 is a new sensor that makes measuring electrical conductivity easy.  Traditional conductivity meters are limited to simple, easily-handled liquids 
          and require careful calibration.  The TC&#8209;X1 is perfect for measuring conductivity for any material that you couldn't before, or measuring the old 
          samples quicker and cheaper than ever.
        </p>
        <Button href="/contact/?template=order">Order Now!</Button>
      </Module>

      <Module image={PetriImage} imageAlt="Top view soil microorganisms Nutrient agar in plate.">
        <h2 className="display-4">Measure the Conductivity of Anything</h2>
        <p className="lead text-muted">
          Non-contact Means Safety and Durability
        </p>
        <p>
          The TC&#8209;X1 can safely measure the conductivity of any sample that can be put on top of the sensing surface - including cement, biological samples, 
          or radioactive goo. The TC&#8209;X1 doesn't foul, wear out, or lose accuracy over time - just remember to charge the battery!
        </p>
        <Button variant="link" href="/contact/?template=demo">Request a demo</Button>
        <Button variant="secondary" href="/casestudies/">See Case Study</Button>
      </Module>

      <HeroBanner 
          variant="small" 
          backgroundColor="primary" 
          backgroundImage={AbstractWavesImage} 
          backgroundShift="15%" 
          className="d-flex align-items-center justify-content-center">
        <h1 className="display-4">Calibration-Free Sensing</h1>
        <p className="lead">
          No calibration is needed when using a standard petri dish to contain the sample.  Use any container with the help of Tayos.
        </p>
        <Button variant="dark" href="/contact/?template=help">Do More with the TC&#8209;X1</Button>
      </HeroBanner>

      <Module right image={ImageTCX1_1} imageAlt="Picture of the TC&#8209;X1 electromagnetic conductivity sensor, showing the on/off switch, tare button, display, and coil sensor surface.">
        <h2 className="display-4">Hands-off Readings</h2>
        <p className="lead text-muted">Get more measurements on more materials</p>
        <p>
          The TC&#8209;X1 is designed to take readings on materials typically too challenging for any other method that requires insertion of a probe into 
          the material or extensive prior calibration.  Some examples of materials include:
        </p>
        <ul>
          <li>Solid Salt Solutions</li>
          <li>Cell and bacterial cultures or other biological material</li>
          <li>Soils, clays, muds, and gravels (partially to fully saturated)</li>
          <li>Cement and cement paste</li>
          {/* <li>Rock cores - even liquid saturated</li> */}
          <li>Sewage, animal, or other semi-toxic/hazardous wastes</li>
          <li>Paint, paste, or slurries</li>
          <li>Concentrated aqueous electrolytes and mixtures of strong acids or bases</li>
          {/* <li>Granular or Porous Media (liquid saturated)</li>
          <li>Foods (in nonmetallic container)</li>
          <li>Wet Cement Pastes</li> */}
        </ul>
        <Button variant="outline-light" href="/manual/">Get the Manual</Button>
      </Module>

      <Module image={CalciumCarbonateImage} imageAlt="Calcium carbonate (CaCO) placed in a petri dish - main alkaline component of rocks such as limestones. Result of the reaction of calcium oxide with carbon dioxide.">
        <h2 className="display-4">Place and Measure</h2>
        <p className="lead text-muted">Lose the expensive electrodes</p>
        <p>
          Using the TC&#8209;X1 is simple: just fill a petri dish with your sample and place it on the sensor coil.  
          Cutting-edge firmware and hardware design uses new quantitative calculation techniques to read electrical 
          conductivity.  Our sensor is ideal for non-magnetic specimens in non-conductive cylindrical vessels centered 
          above a short solenoidal coil like in the TC&#8209;X1. 
        </p>
        <p>
          See more in the <a href="https://ieeexplore.ieee.org/abstract/document/9154723">IEEE Sensors Journal</a>.
        </p>
        <Button variant="outline-secondary" href="/contact/?template=order">Order Now</Button>
        <Button href="/papers/">Read More Research</Button>
      </Module>
    </PageLayout>
  )
}

export default ConductivityMeterPage
